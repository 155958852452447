// ユーザー名
export const MaxLengthOfScreenName = 60;

// ユーザープロフィール・チームプロフィール
export const MaxLengthOfProfile = 3000;

// 姓名
export const MaxLengthOfUserName = 255;

// 会社名
export const MaxLengthOfCompanyName = 60;

// 部署
export const MaxLengthOfDepartment = 255;

// 支店
export const MaxLengthOfBranch = 255;

// 役職
export const MaxLengthOfOfficialPosition = 255;

// チーム名
export const MaxLengthOfTeamName = 60;

// プロジェクトタイトル
export const MaxLengthOfProjectTitle = 100;

// プロジェクト説明文
export const MaxLengthOfProjectDescription = 5000;

// プロジェクトタグ
export const MaxLengthOfProjectTag = 255;

// プロジェクトクレジット
export const MaxLengthOfProjectCredit = 255;

// 撮影者
export const MaxLengthOfPhotographer = 100;

// 事例写真のキャプション
export const MaxLengthOfPostCaption = 255;

// プロジェクト補足資料タイトル
export const MaxLengthOfProjectAttachmentTitle = 255;
