import Button from "@/components/Button";
import { ButtonColor } from "@/components/Button/types/ButtonColor";
import Maybe from "@/components/Maybe";
import {
  CSSProperties,
  FC,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useMemo,
} from "react";
import ReactModal from "react-modal";

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  contentStyle?: CSSProperties;
  overlayStyle?: CSSProperties;
  scrollableTargetId?: string;
  contentRef?: (node: HTMLDivElement) => void;
  icon?: ReactNode;
  title?: string;
  header?: ReactNode;
  btns?: ReactNode;
  closeBtnColor?: ButtonColor;
  closeBtnLabel?: string;
  hiddenCloseBtn?: boolean;
}

const modalStyle: ReactModal.Styles = {
  content: {
    maxWidth: 400,
    inset: "unset",
    position: "relative",
    overflow: "scroll",
    width: "100%",
    padding: 0,
    borderRadius: 8,
    border: "none",
  },
  overlay: {
    position: "fixed",
    backgroundColor: "rgba(0,0,0,0.7)",
    zIndex: 9999,

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 16,
  },
};

const Dialog: FC<PropsWithChildren<Props>> = ({
  children,
  isOpen,
  onClose,
  contentStyle,
  overlayStyle,
  scrollableTargetId,
  contentRef,
  icon,
  title,
  header,
  btns,
  closeBtnColor,
  closeBtnLabel,
  hiddenCloseBtn = false,
}): JSX.Element => {
  useEffect(() => {
    ReactModal.setAppElement("body");
  }, []);

  const style = useMemo<ReactModal.Styles>(() => {
    return {
      content: {
        ...modalStyle.content,
        ...contentStyle,
      },
      overlay: {
        ...modalStyle.overlay,
        ...overlayStyle,
      },
    };
  }, [contentStyle, overlayStyle]);

  const existBtns = useMemo(
    () => !hiddenCloseBtn || !!btns,
    [hiddenCloseBtn, btns],
  );

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={style}
      id={scrollableTargetId}
      ariaHideApp={false}
      contentRef={contentRef}
    >
      <Maybe condition={!!header}>{header}</Maybe>
      <div className="p-24">
        <div className="grid gap-24">
          {(!!title || !!icon) && (
            <div className="grid gap-16 justify-items-center">
              <Maybe condition={!!icon}>{icon}</Maybe>

              <Maybe condition={!!title}>
                <p className="font-bold text-lg leading-normal text-center">
                  {title}
                </p>
              </Maybe>
            </div>
          )}

          <main className="text-sm">{children}</main>

          <Maybe condition={existBtns}>
            <div className="grid gap-12">
              <Maybe condition={!!btns}>{btns}</Maybe>
              <Maybe condition={!hiddenCloseBtn}>
                <Button
                  type="button"
                  size="small"
                  color={closeBtnColor}
                  onClick={onClose}
                >
                  {closeBtnLabel || "閉じる"}
                </Button>
              </Maybe>
            </div>
          </Maybe>
        </div>
      </div>
    </ReactModal>
  );
};

export default Dialog;
