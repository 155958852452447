import {
  AddableNotePin,
  AddableProductVariationPin,
} from "@/features/pin/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State {
  keywords: string[];
  pins: (AddableProductVariationPin | AddableNotePin)[];
}

const initialState = {
  keywords: [],
  pins: [],
} as State;

const slice = createSlice({
  name: "history",
  initialState,
  reducers: {
    addKeyword(state, action: PayloadAction<string>) {
      state.keywords = [action.payload, ...state.keywords]
        .filter((x, i, self) => {
          return self.indexOf(x) === i;
        })
        .slice(0, 8);
    },
    clearKeywords(state) {
      state.keywords = [];
    },
  },
});

export const { addKeyword, clearKeywords } = slice.actions;
export const historyReducer = slice.reducer;
